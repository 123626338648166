<template>
<div class="mobility">
  <LayoutTabs />
  <ContactDetails />
</div>

</template>



<script>

import {defineComponent} from "vue";
import LayoutTabs from "@/components/mobility/LayoutTabs.vue";
import ContactDetails from "@/components/mobility/ContactDetails.vue";

export default defineComponent({
  components: {
    ContactDetails,
    LayoutTabs}
})
</script>


<style scoped>

</style>